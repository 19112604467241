.Description {
  margin-top: 10px;
  width: 35%;
  border: 2px solid #000;
  background: #1f92c1;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
}

.Title {
  width: 100%;
}

.Description div {
  margin: 10px 0;
  text-align: center;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
}

.Description p {
  line-height: 1.3rem;
}

@media (max-width: 600px) {
  .Description {
    width: 100%;
  }
  .Description div {
    text-align: center;
  }
}
