.VideoPage {
  padding: 20px;
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-evenly;
}

.BackButton {
  width: 100px;
  margin: 30px 0 0 8%;
}

@media (max-width: 600px) {
  .VideoPage {
    flex-direction: column;
    align-items: center;
  }
  .BackButton {
    width: 20%;
    margin: 30px 0 0 35%;
  }
}
