@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');

.Filler {
  height: 200px;
}

.TitleContainer img {
  width: 800px;
  cursor: pointer;
}

.Header {
  border-bottom: 2px solid #000; /* Ask sarah if this is ok */
  color: #fff;
  width: 100%;
  background: #1f92c1;

  position: fixed;
  z-index: 50;
  height: 200px;
  top: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Header h1 {
  text-align: center;
  font-family: 'Bangers', cursive;
  color: #5ec3ff;
  font-size: 4rem;
  font-weight: lighter;
  word-spacing: 5px;

  text-shadow: 2px 2px #000;
}

.Buttons {
  margin: 5px;
}

.Buttons button {
  transform: rotate(-10deg);
  color: #fff;
  cursor: pointer;
  border: none;
  margin: 10px;
  background: none;
  font-size: 1.1em;
  padding: 5px;
}

.Buttons button:hover {
  text-decoration: underline;
}

.Buttons button:active {
  background: #000;
  text-decoration: none;
  border-radius: 10px;
}
