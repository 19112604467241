.Modal,
.Container {
  border-radius: 20px;
}

.Modal {
  overflow-wrap: break-word;
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 2px solid #000;
  padding: 16px;
  left: 15%;
  top: 30%;
  transition: all 0.3s ease-out;
}

.Container {
  padding: 20px;
  background: #1f92c1;
  border: 2px solid #000;

  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.Info {
  width: 80%;
  margin: auto;
}

.Container ul li {
  margin: 50px 0;
  font-size: 1.1rem;
  background: #fff;
  border-radius: 20px;
  padding: 5px;
  text-align: center;
}

.CloseModalButton {
  background: black;
  color: white;
  padding: 1px 5px;
  text-align: center;
  cursor: pointer;
  width: 1.55rem;
  border: none;
  font-size: 1.5rem;
}
.SocialMedia {
  width: 100px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
  }
}
