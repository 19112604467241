@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bangers&display=swap);
* {
  box-sizing: border-box;
}
html {
  height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backdrop_BackDrop__3e8db {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal_Modal__G2U34,
.modal_Container__1ti6j {
  border-radius: 20px;
}

.modal_Modal__G2U34 {
  overflow-wrap: break-word;
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 2px solid #000;
  padding: 16px;
  left: 15%;
  top: 30%;
  transition: all 0.3s ease-out;
}

.modal_Container__1ti6j {
  padding: 20px;
  background: #1f92c1;
  border: 2px solid #000;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.modal_Info__2Wkbk {
  width: 80%;
  margin: auto;
}

.modal_Container__1ti6j ul li {
  margin: 50px 0;
  font-size: 1.1rem;
  background: #fff;
  border-radius: 20px;
  padding: 5px;
  text-align: center;
}

.modal_CloseModalButton__18_aj {
  background: black;
  color: white;
  padding: 1px 5px;
  text-align: center;
  cursor: pointer;
  width: 1.55rem;
  border: none;
  font-size: 1.5rem;
}
.modal_SocialMedia__23jvM {
  width: 100px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .modal_Modal__G2U34 {
    width: 500px;
    left: calc(50% - 250px);
  }
}

.header_Filler__2RocI {
  height: 200px;
}

.header_TitleContainer__1Ihxw img {
  width: 800px;
  cursor: pointer;
}

.header_Header__2o08c {
  border-bottom: 2px solid #000; /* Ask sarah if this is ok */
  color: #fff;
  width: 100%;
  background: #1f92c1;

  position: fixed;
  z-index: 50;
  height: 200px;
  top: 0;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.header_Header__2o08c h1 {
  text-align: center;
  font-family: 'Bangers', cursive;
  color: #5ec3ff;
  font-size: 4rem;
  font-weight: lighter;
  word-spacing: 5px;

  text-shadow: 2px 2px #000;
}

.header_Buttons__2HdqX {
  margin: 5px;
}

.header_Buttons__2HdqX button {
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  color: #fff;
  cursor: pointer;
  border: none;
  margin: 10px;
  background: none;
  font-size: 1.1em;
  padding: 5px;
}

.header_Buttons__2HdqX button:hover {
  text-decoration: underline;
}

.header_Buttons__2HdqX button:active {
  background: #000;
  text-decoration: none;
  border-radius: 10px;
}

.item_Item__3pmmq,
.item_Item__3pmmq img {
  width: 100%;
  height: 270px;
}

.item_Item__3pmmq {
  cursor: pointer;
  width: 415px;
  height: 280px;
  border: 2px solid #000;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: 0.3s all;
}

.item_Item__3pmmq img {
  object-fit: cover;
  transition: 1s all;
}

.item_Item__3pmmq img:hover,
.item_Overlay__2_0Kp:hover + img {
  -webkit-filter: blur(5px);
          filter: blur(5px);
}

.item_Item__3pmmq:hover > .item_Overlay__2_0Kp {
  opacity: 1;
}

.item_WorkTitle__3TdLs p {
  margin: 5px 0;
}

.item_Wrapper__1dqfY {
  margin: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.item_Overlay__2_0Kp {
  opacity: 0;
  z-index: 10;
  position: absolute;
  max-width: 400px;

  text-align: center;
  font-size: 1.5rem;
  color: #000;
  background: #fff;
  padding: 20px 50px;
  border-radius: 30px;
  border: 2px solid #000;

  transition: 0.3s all;
}

.item_ProjectTitle__13qzc {
  text-transform: uppercase;
  background: #1f92c1;
  color: #fff;
  padding: 5px;
  border-radius: 10px;
}

.item_ProjectDeets__1H2VG {
  font-size: 1rem;
  margin: 5px;
}

.item_WorkTitle__3TdLs p:hover,
.item_Item__3pmmq:hover + .item_WorkTitle__3TdLs p {
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 600px) {
  .item_Item__3pmmq {
    width: 300px;
  }
}

.items_Items__3sMG5 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
}

.items_Wrapper__13rxn {
  display: -webkit-flex;
  display: flex;
  margin: 20px 0;
}

a {
  text-decoration: none;
  color: #000;
}

.youTubePlayer_videoResponsive__2t1DB {
  width: 60%;
}

.youTubePlayer_videoResponsive__2t1DB iframe {
  border: 2px solid #000;
  border-radius: 20px;
  width: 100%;
}

@media (max-width: 600px) {
  .youTubePlayer_videoResponsive__2t1DB {
    width: 100%;
  }
}

.videoPage_VideoPage__1FPfM {
  padding: 20px;
  display: -webkit-flex;
  display: flex;
  width: 90%;
  margin: auto;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.videoPage_BackButton__5-_Az {
  width: 100px;
  margin: 30px 0 0 8%;
}

@media (max-width: 600px) {
  .videoPage_VideoPage__1FPfM {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .videoPage_BackButton__5-_Az {
    width: 20%;
    margin: 30px 0 0 35%;
  }
}

.description_Description__2UWUf {
  margin-top: 10px;
  width: 35%;
  border: 2px solid #000;
  background: #1f92c1;
  border-radius: 20px;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  height: 50%;
}

.description_Title__3b-AS {
  width: 100%;
}

.description_Description__2UWUf div {
  margin: 10px 0;
  text-align: center;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
}

.description_Description__2UWUf p {
  line-height: 1.3rem;
}

@media (max-width: 600px) {
  .description_Description__2UWUf {
    width: 100%;
  }
  .description_Description__2UWUf div {
    text-align: center;
  }
}

.picturePage_PicturePage__AghO3 {
  margin: 10px;
  width: 40%;
}

.picturePage_PicturePage__AghO3 img {
  width: 100%;
  margin: 0 0 20px 0;
  border: 2px solid #000;
  border-radius: 20px;
}

@media (max-width: 600px) {
  .picturePage_PicturePage__AghO3 {
    width: 100%;
  }
}

