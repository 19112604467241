.Item,
.Item img {
  width: 100%;
  height: 270px;
}

.Item {
  cursor: pointer;
  width: 415px;
  height: 280px;
  border: 2px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
}

.Item img {
  object-fit: cover;
  transition: 1s all;
}

.Item img:hover,
.Overlay:hover + img {
  filter: blur(5px);
}

.Item:hover > .Overlay {
  opacity: 1;
}

.WorkTitle p {
  margin: 5px 0;
}

.Wrapper {
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.Overlay {
  opacity: 0;
  z-index: 10;
  position: absolute;
  max-width: 400px;

  text-align: center;
  font-size: 1.5rem;
  color: #000;
  background: #fff;
  padding: 20px 50px;
  border-radius: 30px;
  border: 2px solid #000;

  transition: 0.3s all;
}

.ProjectTitle {
  text-transform: uppercase;
  background: #1f92c1;
  color: #fff;
  padding: 5px;
  border-radius: 10px;
}

.ProjectDeets {
  font-size: 1rem;
  margin: 5px;
}

.WorkTitle p:hover,
.Item:hover + .WorkTitle p {
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 600px) {
  .Item {
    width: 300px;
  }
}
